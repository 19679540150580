<template>
  <div class="basketball-game-container layout-border">
    <div class="basketball-game-box">
      <div class="basketball-game-area">
        <img class="bgIamge" />
        <div class="basketball-game-content">
          <div class="trumpter" @click="playAudio">
            <!-- <img class="volume-button" :src="volumeImgSrc" @ /> -->
            <AudioPlayer :isPlay="isStartPlaying" :audioUrl="songUrl" />
          </div>
          <img class="objectDisplay" :src="board" />

          <div v-if="playAnimation" class="ballDrop">
            <img class="ball" :src="shootball" alt="" />
          </div>
          <img class="basketDisplay" :src="basket" />
        </div>

        <div class="bottonCont">
          <div class="redBackground"></div>
          <div class="bottonsWrapper">
            <div
              v-for="(item, index) in optionList[question]"
              :key="index"
              class="option-item"
              :class="{
                shake:
                  item.id !== answerList[question].id &&
                  isClicked &&
                  index === chooseIndex,
              }"
              @click="foundAnswer(index)"
            >
              <img v-if="item.showObj" :src="item.bottomImg" alt="" />
              <img v-else :src="basketball_transparent" alt="" />
            </div>
          </div>
        </div>

        <Star :totalStarNumber="totalStarNumber" :currentIndex="stepsIndex" />
      </div>
    </div>
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :numBers="stepsIndex"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @updateNumbers="updateNumbers($event)"
    ></PageButton>
  </div>
</template>
<script>
import Star from "../Star";
import AudioPlayer from "@/components/Course/GamePage/AudioPlayer";

import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  components: {
    PageButton,
    Star,
    AudioPlayer,
  },
  props: {
    lessonId: {
      type: Number,
      default: 0,
    },
    bgImage: {
      type: String,
      default: "",
    },
    answerList: {
      type: Array,
      require: true,
      default: () => [],
    },
    optionList: {
      type: Array,
      require: true,
      default: () => [],
    },
    audioList: {
      type: Array,
      require: true,
      default: () => [],
    },
    totalStarNumber: {
      type: Number,
      require: false,
      default: 7,
    },
  },
  data() {
    return {
      basket: require("@/assets/img/16-GAMES/G45-basketball/basket.svg"),
      board: require("@/assets/img/16-GAMES/G45-basketball/board.svg"),
      basketball_transparent: require("@/assets/img/16-GAMES/G45-basketball/basketball-transparent.svg"),
      shootball: require("@/assets/img/16-GAMES/G45-basketball/basketball-transparent.svg"),
      volumeImgSrc: require("@/assets/img/06-Sound/sound-white.svg"),
      playAnimation: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 0,
            endNumberRange: 0,
          },
        ],
      },
      isLastStep: false,
      stepsIndex: 0,
      question: 0,

      basketballSocketInfo: {},
      audioSocketInfo: {},
      isClicked: false,
      chooseIndex: null,
      isStartPlaying: null,
      songUrl: null,
    };
  },
  mounted() {
    this.$bus.$on("clickBasketballGame", ({ index }) => {
      this.foundAnswer(index, true);
    });
    this.$bus.$on("clickBasketballGameAudio", () => {
      this.playAudio("socket", true);
    });
    this.optionList[this.question] = this.randomList(this.optionList[this.question])
  },
  beforeDestroy() {
    this.$bus.$off("clickBasketballGame");
    this.$bus.$off("clickBasketballGameAudio");
  },
  watch: {
    basketballSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20035,
          data: { value },
          text: "BasketballGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003501,
          data: { value },
          text: "BasketballGame点击音频socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    playAudio(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.audioSocketInfo = {
          value: Math.random(),
        };
      }
      this.isStartPlaying = Math.random();
      this.songUrl = this.audioList[this.stepsIndex];
    },
    updateNumbers(value) {
      this.numBers = value;
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    foundAnswer(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.basketballSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.chooseIndex = index;
      if (
        this.answerList[this.question].id ===
        this.optionList[this.question][index].id
      ) {
        this.shootball = this.optionList[this.question][index].bottomImg;
        this.playAnimation = true;
        playCorrectSound(true, false);
        this.stepsIndex++;
        this.optionList[this.question][index].showObj = false;
        this.timer = setTimeout(() => {
          this.optionList[this.question][index].showObj = true;
          this.playAnimation = false;
          if (this.question + 1 < this.answerList.length) {
            this.question++;
            this.optionList[this.question] = this.randomList(this.optionList[this.question])
          } else {
            this.isLastStep = true;
            playCorrectSound();
            startConfetti();
          }
          this.playAudio("socket");
        }, 2500);
      } else {
        this.isClicked = true;
        playCorrectSound(false);
        setTimeout(() => {
          this.isClicked = false;
        }, 1000);
      }
    },
           randomList: function(rand){
          return rand.sort(function(){return 0.5 - Math.random()});
        }
  },
};
</script>
<style lang="scss" scoped>
.basketball-game-container {
  .basketball-game-box {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 48px;
    position: relative;
    overflow: hidden;
    .basketball-game-area {
      text-align: -webkit-center;
      position: absolute;
      height: 100%;
      width: 100%;
      background: #f9f6e6;
      border-radius: 48px;
      margin: auto;
      overflow: hidden;
      .bgIamge {
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      .basketball-game-content {
        position: relative;
        width: -moz-fit-content;
        width: 100%;
        top: 17%;
        height: calc(80% - 150px);
        justify-content: center;
        z-index: 0;
        border-radius: 10%;
        display: flex;
      }
    }
  }
}
.ballDrop {
  //  background-color: #1b4589;
  height: 100%;
  width: 13%;
  z-index: 800;

  .ball {
    width: 100%;
    animation: ballAnim 3s 1;
    animation-direction: normal;
  }

  @keyframes ballAnim {
    0% {
      margin-top: 0;
    }

    100% {
      margin-top: 500%;
    }
  }
}
.trumpter {
  position: absolute;
  left: 47%;
  top: 6%;
  width: 7vh;
  height: 7vh;
  z-index: 1000;
  background-color: #224e96;
  border-radius: 50%;
  text-align: center;
  .volume-button {
    cursor: pointer;
  }
  img {
    width: 5vh;
    height: 5vh;
  }
}
.trumpter:hover {
  background-color: #1b4589;
}

.bottonCont {
  bottom: 0;
  width: 70%;
  /* margin-top: 45%; */
  height: 150px;
  position: absolute;
  display: flex;
  justify-content: center;
  left: 10%;
  .redBackground {
    width: 100%;
    height: 50%;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    background-color: #d87065;
    position: absolute;
    bottom: 0;
  }
  .bottonsWrapper {
    display: flex;
    bottom: 0;
    height: -moz-fit-content;
    justify-content: space-between;
    height: 100%;
    width: 78%;
    position: absolute;

    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    background-color: #ffffff;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 20px;
  }
}
.option-item {
  max-width: 27%;
  min-width: 100px;
  min-height: 100px;
  z-index: 1;
  border-radius: 15px;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    color: #fff;
    font-size: 25px;
  }
  span:nth-child(2) {
    color: #fff;
    font-size: 30px;
  }
  img {
    max-width: 80%;
  }
}
.option-item:active {
  transform: scale(0.95);
}
.option-item:hover {
  min-width: 100px;
  min-height: 100px;
  border-radius: 15px;
  border-bottom: 0px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  span {
    color: #fff;
    font-size: 25px;
  }
  span:nth-child(2) {
    color: #fff;
    font-size: 30px;
  }
}

.objectDisplay {
  position: absolute;
  height: 70%;
  //  bottom: 16%;
  //  left: 25%;
  width: 100%;
  z-index: 700;
}
.basketDisplay {
  position: absolute;
  top: 55%;
  height: 50%;
  width: 100%;
  z-index: 1700;
}
.soundIcon {
  position: absolute;
  width: 10%;
  top: 65%;
  left: 45%;
}

.blueBg {
  background: #abeaff !important;
}
</style>
