var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "basketball-game-container layout-border" },
    [
      _c("div", { staticClass: "basketball-game-box" }, [
        _c(
          "div",
          { staticClass: "basketball-game-area" },
          [
            _c("img", { staticClass: "bgIamge" }),
            _c("div", { staticClass: "basketball-game-content" }, [
              _c(
                "div",
                { staticClass: "trumpter", on: { click: _vm.playAudio } },
                [
                  _c("AudioPlayer", {
                    attrs: {
                      isPlay: _vm.isStartPlaying,
                      audioUrl: _vm.songUrl,
                    },
                  }),
                ],
                1
              ),
              _c("img", {
                staticClass: "objectDisplay",
                attrs: { src: _vm.board },
              }),
              _vm.playAnimation
                ? _c("div", { staticClass: "ballDrop" }, [
                    _c("img", {
                      staticClass: "ball",
                      attrs: { src: _vm.shootball, alt: "" },
                    }),
                  ])
                : _vm._e(),
              _c("img", {
                staticClass: "basketDisplay",
                attrs: { src: _vm.basket },
              }),
            ]),
            _c("div", { staticClass: "bottonCont" }, [
              _c("div", { staticClass: "redBackground" }),
              _c(
                "div",
                { staticClass: "bottonsWrapper" },
                _vm._l(_vm.optionList[_vm.question], function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "option-item",
                      class: {
                        shake:
                          item.id !== _vm.answerList[_vm.question].id &&
                          _vm.isClicked &&
                          index === _vm.chooseIndex,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.foundAnswer(index)
                        },
                      },
                    },
                    [
                      item.showObj
                        ? _c("img", { attrs: { src: item.bottomImg, alt: "" } })
                        : _c("img", {
                            attrs: { src: _vm.basketball_transparent, alt: "" },
                          }),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("Star", {
              attrs: {
                totalStarNumber: _vm.totalStarNumber,
                currentIndex: _vm.stepsIndex,
              },
            }),
          ],
          1
        ),
      ]),
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          numBers: _vm.stepsIndex,
          isLastStep: _vm.isLastStep,
        },
        on: {
          clickNext: _vm.nextPage,
          updateNumbers: function ($event) {
            return _vm.updateNumbers($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }